<template>
  <div class="floating-button" :class="visible ? '!z-[10002]' : ''">
    <button type="button" :title="$t('support.sidebar.open.label')" @click="handleClick" :disabled="loading">
      <IconCommentQuestion :width="32" :height="32" v-if="!visible" />
      <IconClose :width="32" :height="32" v-else />
    </button>
  </div>

  <PrimeSidebar
    :header="$t('support.sidebar.header')"
    v-model:visible="visible"
    position="right"
    class="sidebar-small"
    v-if="flyoutInfo"
  >
    <div class="context-list">
      <div class="list-item" v-for="(info, idx) of flyoutInfo.list" :key="info.key || idx">
        <a
          href="#"
          @click.prevent="() => { visible = false; dialogVisible = true }"
          v-tooltip.right-center="$t('support.sidebar.support.tooltip')"
          class="item-link"
          v-if="info.key === 'support'"
        >
          <IconBookshelf class="link-icon" :width="24" :height="24" />
          <span class="link-text">{{ info.label }}</span>
        </a>

        <a
          :href="info.key === 'wapp' ? 'https://wa.me/4915142267805' : info.value"
          v-tooltip.right-center="info.tooltip"
          class="item-link"
          v-else
        >
          <IconEmailOutline class="link-icon" :width="24" :height="24" v-if="info.key == 'mail'" />
          <IconPhoneOutline class="link-icon" :width="24" :height="24" v-if="info.key == 'tel'" />
          <IconWhatsApp class="link-icon" :width="24" :height="24" v-if="info.key == 'wapp'" />
          <span class="link-text">{{ info.key === 'wapp' ? '0151 - 42267805' : info.label }}</span>
        </a>
      </div>
    </div>
    <div v-if="flyoutInfo.supportperson">
      <PersonComponent :person-id="flyoutInfo.supportperson" />
    </div>
  </PrimeSidebar>

  <SupportTicketDialog v-model:visible="dialogVisible" />
</template>

<script lang="ts">
// Default Export, used for IDE auto-importing only
export default {
  name: 'SupportSidebar'
}
</script>

<script setup lang="ts">
import type { FlyoutDto } from '@/types/contracts/generated/models/flyoutDto';

import IconBookshelf from '~icons/mdi/bookshelf';
import IconClose from '~icons/mdi/close';
import IconCommentQuestion from '~icons/mdi/comment-question';
import IconEmailOutline from '~icons/mdi/email-outline';
import IconPhoneOutline from '~icons/mdi/phone-outline';
import IconWhatsApp from '~icons/mdi/whatsapp';
import { storeToRefs } from 'pinia';
import PrimeSidebar from 'primevue/sidebar';
import { onMounted, ref } from 'vue';

import SupportTicketDialog from '@/components/dialogs/SupportTicketDialog.vue';
import { useCampusFlyout } from '@/composables/http/use-campus-flyout';
import { useCampusEpmApi } from '@/composables/http/use-campus-epm-api';
import { useMiscStore } from '@/stores/misc';
import PersonComponent from '@/components/shared/PersonComponent.vue';

// Stores
const { invalidateDashboard } = useCampusEpmApi();
const { getFlyoutInfo } = useCampusFlyout();

// States
const { isAssl } = storeToRefs(useMiscStore());
const flyoutInfo = ref<FlyoutDto>();
const loading = ref<boolean>(false);
const visible = ref<boolean>(false);
const dialogVisible = ref<boolean>(false);

/**
 * Component Mounted
 */
onMounted(async () => {
  if (loading.value) {
    return;
  }
  loading.value = true;

  try {
    let result = await getFlyoutInfo();
    flyoutInfo.value = result;
    isAssl.value = !!result.supportperson;
  } catch (err) {
    console.error(err);
  } finally {
    loading.value = false;
  }
});

/**
 * Open Sidebar
 */
const handleClick = () => {
  visible.value ? (visible.value = false) : (visible.value = true);
  invalidateDashboard();
};
</script>

<style scoped>
.context-list {
  @apply w-full flex flex-col gap-2;
}

.list-item {
  @apply w-full flex flex-row;
}

.item-link {
  @apply w-full flex flex-row gap-3 items-center px-2 py-1.5 rounded;
  @apply duration-300 ease-in-out transition-colors;

  & .link-icon {
    @apply shrink-0 basis-6;
  }

  & .link-text {
    @apply text-base;
  }

  &:hover {
    @apply bg-primary-50;
  }
}

.floating-button {
  @apply fixed bottom-4 right-4 pointer-events-none;
  z-index: 9999;

  @screen md {
    @apply bottom-8 right-8;
  }
}

button {
  @apply w-12 h-12 flex items-center justify-center border-none rounded-full pointer-events-auto;
  @apply duration-300 ease-in-out;
  transition-property: background-color, box-shadow, color, opacity;
  box-shadow: 0 0 0 0px transparent;

  @screen md {
    @apply w-14 h-14;
  }

  &:disabled {
    @apply cursor-not-allowed;
    @apply text-zinc-50 bg-zinc-600 opacity-25;
  }

  &:not(:disabled) {
    @apply cursor-pointer;
    @apply text-primary-50 bg-primary-500 opacity-100;
  }

  &:not(:disabled):hover {
    @apply bg-primary-600 shadow-primary-600/25;
    box-shadow: 0 0 0 4px var(--tw-shadow-color);
  }
}
</style>
