import type { StudienbuchDto } from '@/types/contracts/generated/models/studienbuchDto';
import type { StudiengangDto } from '@/types/contracts/generated/models/studiengangDto';
import type {AxiosResponse, ResponseType} from 'axios';
import type { StudyPlanMoveDto } from '@/types/contracts/generated/models/studyPlanMoveDto';
import type { CompetenceSemester } from '@/models/competence-check';
import type { PruefungsanmeldungsForm } from '@/types/contracts/generated/models/pruefungsanmeldungsForm';
import type { PruefungDto } from '@/types/contracts/generated/models/pruefungDto';
import type { PruefungAnmeldungTerminDto } from '@/types/contracts/generated/models/pruefungAnmeldungTerminDto';
import type { PruefungsergebnisDetailDto } from '@/types/contracts/generated/models/pruefungsergebnisDetailDto';
import type { StudyBookExamSignupDto } from '@/types/contracts/generated/models/studyBookExamSignupDto';
import type { StudyBookExamSubmissionForm } from '@/types/contracts/generated/models/studyBookExamSubmissionForm';
import type { StudyBookExamSubmissionDto } from '@/types/contracts/generated/models/studyBookExamSubmissionDto';
import type { StudyplanDto } from '@/types/contracts/generated/models/studyplanDto';
import type { PruefungsabmeldungsForm } from '@/types/contracts/generated/models/pruefungsabmeldungsForm';

// @todo type is missing
//import type {StudyBookExamStateDto} from "@/types/contracts/generated/models/studyBookExamStateDto";
type StudyBookExamStateDto = any;

import type { PersonDto } from '@/types/contracts/generated/models/personDto';
import type { InfoMessage } from '@/types/contracts/generated/models/infoMessage';
import type { ModulbeschreibungDto } from '@/types/contracts/generated/models/modulbeschreibungDto';
import type { KompetenzprofilDto } from '@/types/contracts/generated/models/kompetenzprofilDto';
import { useResource } from '@/composables/http/use-resource';
import type {StudienbuchWahlAnmeldungForm} from "@/types/contracts/generated/models/studienbuchWahlAnmeldungForm";
import type {SpezialisierungDto} from "@/types/contracts/generated/models/spezialisierungDto";
import type {ModulwahlDto2} from "@/types/contracts/generated/models/modulwahlDto2";
import type {ObjectionOverviewDto} from "@/types/contracts/generated/models/objectionOverviewDto";
import type { PruefungsdozentTnDto } from '@/types/contracts/generated/models/pruefungsdozentTnDto';

const baseUrl = `${import.meta.env.VITE_API_CAMPUS}/epm`;

export function useCampusEpmApi() {
  const { makeGetRequest, makePostRequest, makeDeleteRequest, makeGetRequestWithResponse, makePutRequest } = useResource();

  const fetchCoursesOfStudies = async (): Promise<StudiengangDto[]> => makeGetRequest(`${baseUrl}/studiengaenge`);

  const fetchStudyplan = async (studiengangid: number): Promise<StudienbuchDto> =>
    makeGetRequest(`${baseUrl}/studybook/${studiengangid}`);

  const fetchDefaultStudyplan = async (id: number): Promise<StudyplanDto> =>
    makeGetRequest(`${baseUrl}/studyplan/${id}`);

  const ackLayer = async (layerId: number): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/studybook/layer/${layerId}`);

  const fetchCompetenceCheckResult = async (): Promise<CompetenceSemester[]> =>
    makeGetRequest(`${baseUrl}/competence/result`);

  const moveCourse = async (move: StudyPlanMoveDto): Promise<AxiosResponse> => makePostRequest(`${baseUrl}/move`, move);

  const getAllExamDates = async (): Promise<PruefungDto[]> => makeGetRequest(`${baseUrl}/pruefungstermin`);

  const getExamDatesByExamId = async (examId: number): Promise<StudyBookExamSignupDto> =>
    makeGetRequest(`${baseUrl}/pruefungstermin/${examId}`);

  const getExamDateByExamIdAndExamDateId = async (
    examId: number,
    examDateId: number
  ): Promise<PruefungAnmeldungTerminDto> => makeGetRequest(`${baseUrl}/pruefungstermin/${examId}/${examDateId}`);

  const postExamSignup = async (data: PruefungsanmeldungsForm) =>
    makePostRequest(`${baseUrl}/pruefungstermine/anmeldung`, data);

  const postExamSignOff = async (data: PruefungsabmeldungsForm) =>
    makePostRequest(`${baseUrl}/pruefungstermine/abmeldung`, data);

  const getExamResultDetails = async (id: number, type: string): Promise<PruefungsergebnisDetailDto> =>
    makeGetRequest(`${baseUrl}/pruefungsergebnis/${type}/${id}`);

  const getExamSubmissionDetails = async (id: number): Promise<StudyBookExamSubmissionDto> =>
    makeGetRequest(`${baseUrl}/pruefungsergebnissubmission/${id}`);

  const postExamSubmission = async (form: StudyBookExamSubmissionForm): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/pruefungsergebnissubmission`, form);

  const postExamSubmissionAdditionalFiles = async (form: FormData): Promise<AxiosResponse> =>
      makePostRequest(`${baseUrl}/pruefungsergebnissubmission/additionalFiles`, form);

  const postExamSubmissionTemp = async (form: StudyBookExamSubmissionForm): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/pruefungsergebnissubmission/temp`, form);

  const bufferExamSubmission = async (form: StudyBookExamSubmissionForm, responseType: ResponseType = "json"): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/pruefungsergebnisbuffer`, form, { responseType: responseType });

  const getPossibleExamPersons = async (examId: number, query: string): Promise<PersonDto[]> =>
    makeGetRequest(`${baseUrl}/moeglichedozenten/${examId}`, { params: { q: query } });

  const getExamState = async (examId: number): Promise<StudyBookExamStateDto> =>
    makeGetRequest(`${baseUrl}/pruefungsstatus/${examId}`);

  const deleteEpmUpload = async (fileId: number): Promise<AxiosResponse> =>
    makeDeleteRequest(`${baseUrl}/file/${fileId}`);

  const deleteEpmVideoUpload = async (uuid: string): Promise<AxiosResponse> =>
    makeDeleteRequest(`${baseUrl}/video/${uuid}`);

  const submitScientificProject = async (formData: FormData): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/uploadandsubmit`, formData);

  const confirmSignUp = async (id: number | string): Promise<InfoMessage> =>
    makeGetRequest(`${baseUrl}/confirmsignup/${id}`);

  const resendSignUp = async (id: number | string): Promise<InfoMessage> =>
    makeGetRequest(`${baseUrl}/resendsignup/${id}`);

  const invalidateDashboard = async (): Promise<any> => makeGetRequest(`${baseUrl}/invalidateDashboard`);

  const reviewSignUp = async (buchungid: number, pdtnid: number): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/einsicht/${pdtnid}/${buchungid}`);

  const cancelReviewSignUp = async (id: number): Promise<AxiosResponse> =>
    makeDeleteRequest(`${baseUrl}/einsicht/${id}`);

  const getModuleDetail = async (moduleId: number): Promise<ModulbeschreibungDto> =>
    makeGetRequest(`${baseUrl}/modul/${moduleId}`);

  const submitKompetenzprofil360einladung = async (formData: any): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/kompetenzprofil360einladung`, formData);

  const getCompetenceProfile = async (): Promise<KompetenzprofilDto> => makeGetRequest(`${baseUrl}/kompetenzprofil`);

  const getCoverSheet = async (peid: number, date: string): Promise<AxiosResponse> =>
    makeGetRequestWithResponse(`${baseUrl}/deckblatt/${peid}/${date}`, { responseType: 'blob' });

  const getStammdatenCheck = async (): Promise<AxiosResponse> =>
      makeGetRequestWithResponse(`${baseUrl}/stammdatencheck`);

  const getWahlmodule = async (courseId: string, fieldId: string): Promise<StudienbuchWahlAnmeldungForm> =>
      makeGetRequest(`${baseUrl}/wahlmodule/${courseId}/${fieldId}`);

  const saveWahlmodule = async (form: StudienbuchWahlAnmeldungForm): Promise<AxiosResponse> =>
      makePostRequest(`${baseUrl}/wahlmodule`, form);

  const getSpecializationInfo = async(stnId: string, moduleId: string): Promise<SpezialisierungDto> =>
      makeGetRequest(`${baseUrl}/spezialisierung/${stnId}/${moduleId}`);

  const submitSpecializationForm = async(form: FormData): Promise<AxiosResponse> =>
      makePostRequest(`${baseUrl}/spezialisierung`, form);

  const fetchModulMoveOptions = async(stnid: number, modulid: number): Promise<ModulwahlDto2> =>
      makeGetRequest(`${baseUrl}/modulwahl2/${stnid}/${modulid}`);

  const fetchObjections = async(peid: number): Promise<ObjectionOverviewDto> =>
      makeGetRequest(`${baseUrl}/objection/${peid}`);

  const saveObjection = async(formData: FormData): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/objection`, formData);

  const editObjection = async(formData: FormData): Promise<AxiosResponse> =>
    makePutRequest(`${baseUrl}/objection`, formData);

  const deleteObjection = async(id: PruefungsdozentTnDto["id"]): Promise<AxiosResponse> =>
    makeDeleteRequest(`${baseUrl}/objection/${id}`);

  return {
    fetchCoursesOfStudies,
    fetchStudyplan,
    fetchDefaultStudyplan,
    ackLayer,
    moveCourse,
    fetchCompetenceCheckResult,
    getAllExamDates,
    getExamDatesByExamId,
    getExamDateByExamIdAndExamDateId,
    postExamSignup,
    postExamSignOff,
    getExamResultDetails,
    getExamSubmissionDetails,
    postExamSubmission,
    postExamSubmissionAdditionalFiles,
    bufferExamSubmission,
    getPossibleExamPersons,
    getExamState,
    deleteEpmUpload,
    submitScientificProject,
    confirmSignUp,
    resendSignUp,
    deleteEpmVideoUpload,
    invalidateDashboard,
    reviewSignUp,
    getModuleDetail,
    submitKompetenzprofil360einladung,
    getCompetenceProfile,
    postExamSubmissionTemp,
    getCoverSheet,
    cancelReviewSignUp,
    getStammdatenCheck,
    getWahlmodule,
    saveWahlmodule,
    getSpecializationInfo,
    submitSpecializationForm,
    fetchModulMoveOptions,
    fetchObjections,
    saveObjection,
    editObjection,
    deleteObjection
  };
}
