<template>
  <article ref="wrapper" class="px-4 pb-6 border-0 border-b border-dashed border-zinc-200 lg:px-8">
    <RouterLink :to="searchResult.link ?? '#'" class="flex items-start gap-4 search-result">
      <div class="flex p-2 rounded-full bg-zinc-50 shrink-0 search-result-icon">
        <template v-if="searchResult.filetype === 'link'">
          <IconLinkVariant />
        </template>
        <template v-else-if="searchResult.filetype === 'pdf'">
          <IconFilePDFBox />
        </template>
        <template v-else>
          <IconFileOutline />
        </template>
      </div>
      <div>
        <div class="font-semibold">{{ searchResult.headline }}</div>
        <div class="flex flex-col gap-2 my-1 md:flex-row">
          <template v-if="searchResult.created">
            <div class="flex flex-row gap-1">
              <DateTime :date="searchResult.created" format="shortDateTime" />
              <span>{{ t('calendar.clock') }}</span>
            </div>
          </template>
          <PrimeBadge severity="secondary" class="text-xs font-normal w-fit bg-primary-500/10 text-primary-700">
            {{ t(`search.category.${searchResult.type}`) }}
          </PrimeBadge>
        </div>
        <PersonComponent
          v-if="searchResult.personid && wrapperVisible"
          :person-id="searchResult.personid"
          :options="{ inline: true, image: true, size: 'sm' }"
          class="my-2"
        />
        <div v-if="searchResult.htmlview" v-html="searchResult.htmlview" class="overflow-hidden hyphens-auto"></div>
        <a
          v-if="searchResult.subheadline && searchResult.sublink"
          @click.stop.prevent="onDownload"
          class="flex flex-row items-center gap-2"
        >
          <template v-if="searchResult.subfiletype === 'link'">
            <IconLinkVariant />
          </template>
          <template v-else-if="searchResult.subfiletype === 'pdf'">
            <IconFilePDFBox />
          </template>
          <template v-else>
            <IconFileOutline />
          </template>
          <span>
            {{ searchResult.subheadline }}
          </span>
        </a>
      </div>
    </RouterLink>
  </article>
</template>

<script setup lang="ts">
import type { SearchResult } from '@/types/contracts/generated/models/searchResult';

import { useElementVisibility } from '@vueuse/core';
import IconFilePDFBox from '~icons/mdi/file-pdf-box';
import IconFileOutline from '~icons/mdi/file-outline';
import IconLinkVariant from '~icons/mdi/link-variant';
import PrimeBadge from 'primevue/badge';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterLink } from 'vue-router';

import { useDownload } from '@/composables/use-download';
import DateTime from '@/components/common/DateTime.vue';
import PersonComponent from '@/components/shared/PersonComponent.vue';

// Define Component
const props = defineProps<{
  searchResult: SearchResult;
  term?: string;
}>();

// Stores
const { downloadItem } = useDownload();
const { t } = useI18n();

// States
const wrapper = ref(null);
const wrapperVisible = useElementVisibility(wrapper);

/**
 * Handle Item Download
 * @todo Check if import.meta.env.VITE_API_CAMPUS is really necessary.
 */
function onDownload() {
  downloadItem(`${import.meta.env.VITE_API_CAMPUS}${props.searchResult.sublink}`);
}
</script>

<style scoped>
.search-result {
  &:hover {
    @apply no-underline;
    .search-result-icon {
      @apply bg-zinc-200;
    }
  }
}

</style>
