<template>
  <BaseDialog
    :title="$t('support.dialog.header')"
    :actions="actions"
    v-model:visible="visible"
    @update:visible="onChange"
    :dismissable="false"
    closable
  >
    <!-- <PrimeMessage severity="success" :closable="false" class="mt-0" v-if="submitted">
      {{ $t('support.dialog.success') }}
    </PrimeMessage> -->

    <form @submit.prevent="onSubmit" class="support-ticket-dialog">
      <FormControl :context="fieldSubject" v-slot="props">
        <InputField v-bind="props" v-model="fieldSubject.value.value" :disabled="loading" />
      </FormControl>

      <FormControl :context="fieldMessage" v-slot="props">
        <TextareaField v-bind="props" v-model="fieldMessage.value.value" :disabled="loading" resize="vertical" auto-resize />
      </FormControl>

      <FormControl :context="fieldFiles" v-slot="props" :label="false">
        <UploadField v-bind="props" v-model="fieldFiles.value.value" :disabled="loading" />
      </FormControl>

      <FormControl :context="fieldAssl" v-slot="props" :label="false" :error-message="t('support.dialog.error_checkbox')">
        <CheckboxField
          v-bind="props"
          :label="$t('support.dialog.assl')"
          v-model="fieldAssl.value.value"
          :disabled="loading"
          required
        />
      </FormControl>
      <div class="flex justify-end">
        <SecondaryButton
          size="small"
          :label="$t('support.dialog.assl_more_info')"
          @click="showExtended = !showExtended"
        />
      </div>
      <PrimeMessage v-if="showExtended" severity="info" class="mb-4" @close="showExtended = false">
        <p class="text-sm">
          {{ $t('support.dialog.assl_extended1') }}
        </p>
        <p class="text-sm">
          {{ $t('support.dialog.assl_extended2') }}
        </p>
        <p class="text-sm">
          {{ $t('support.dialog.assl_extended3') }}
        </p>
      </PrimeMessage>
    </form>
  </BaseDialog>
</template>

<script lang="ts">
export interface SupportTicketForm {
  /**
   * Support Ticket Subject
   */
  subject: string;

  /**
   * Support Ticket Message
   */
  message: string;

  /**
   * Support Ticket Files
   */
  files: File[];

  /**
   * ASSL Mode
   */
  assl?: boolean;
}

export interface SupportTicketDialogProps {
  /**
   * Dialog Visibility
   */
  visible: boolean;
}

export interface SupportTicketDialogEmits {
  /**
   * Change Modal Visibility Event Listener
   */
  (ev: 'update:visible', value: boolean): void;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'SupportTicketDialog'
}
</script>

<script lang="ts" setup>
import PrimeMessage from 'primevue/message';
import { useField, useForm } from 'vee-validate';
import { computed, nextTick, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { array, boolean, object, string } from 'yup';

import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import BaseDialog, { type BaseDialogAction } from '@/components/dialogs/BaseDialog.vue';
import CheckboxField from '@/components/form/controls/CheckboxField.vue';
import InputField from '@/components/form/controls/InputField.vue';
import TextareaField from '@/components/form/controls/TextareaField.vue';
import UploadField from '@/components/form/controls/UploadField.vue';
import FormControl from '@/components/form/FormControl.vue';
import { useCampusSupport } from '@/composables/http/use-campus-support';
import {storeToRefs} from "pinia";
import {useSitemapStore} from "@/stores/sitemap";
import { useToast } from 'primevue/usetoast';

// Define Component
const props = defineProps<SupportTicketDialogProps>();
const emits = defineEmits<SupportTicketDialogEmits>();

// Stores
const { t } = useI18n();
const toast = useToast();
const { postSupportRequest } = useCampusSupport();
const { theme } = storeToRefs(useSitemapStore());

// States
const visible = ref<boolean>(props.visible);
const loading = ref<boolean>(false);
const submitted = ref<boolean>(false);
const showExtended = ref<boolean>(false);

const actions = computed<BaseDialogAction[]>(() => {
  return [
    {
      type: 'primary',
      label: t('support.dialog.submit'),
      loading: loading.value,
      disabled: !form.meta.value.valid,
      onClick: async () => {
        await await onSubmit();
        return false;
      }
    }
  ];
});

// Form
const form = useForm<SupportTicketForm>({
  validationSchema: object().shape({
    subject: string().required().label('support.dialog.subject'),
    message: string().required().label('support.dialog.messagetext'),
    files: array().optional().label('support.dialog.files'),
    assl: boolean().required(t('support.dialog.assl')).isTrue(t('support.dialog.assl')).label('support.dialog.assl')
  }),
  validateOnMount: false
});

const fieldSubject = useField<string>('subject', undefined, {
  label: t('support.dialog.subject'),
  form
});

const fieldMessage = useField<string>('message', undefined, {
  label: t('support.dialog.messagetext'),
  form
});

const fieldFiles = useField<File[]>('files', undefined, {
  label: t('support.dialog.files'),
  form
});

const fieldAssl = useField<boolean>('assl', undefined, {
  type: 'checkbox',
  label: t('support.dialog.assl'),
  form
});

/**
 * Watch visible state
 */
watch(
  () => props.visible,
  async (newValue) => {
    visible.value = newValue;

    if (newValue) {
      await nextTick();
      form.resetForm();
    }
  }
);

/**
 * Submit form Handler
 */
const onSubmit = form.handleSubmit(async (values) => {
  if (loading.value) {
    return;
  }
  loading.value = true;

  // Prepare FormData
  const formData = new FormData();
  formData.set('subject', values.subject);
  formData.set('messagetext', values.message);
  formData.set('assl', values.assl ? 'true' : 'false');
  formData.set('institute', theme.value ?? '');
  if (values.files) {
    values.files.forEach((file) => {
      formData.append('files', file, file.name);
    });
  }

  // Post Form
  try {
    if ((import.meta.env?.STORYBOOK || 'false') == 'true') {
      console.log([...formData.entries()]);
      await new Promise((resolve) => setTimeout(resolve.bind(null, null), 3500));
    } else {
      await postSupportRequest(formData)
    }
    submitted.value = true;
    toast.add({
      summary: t('support.dialog.toast.summary'),
      detail: t('support.dialog.toast.detail'),
      severity: "success",
      life: import.meta.env.VITE_TOAST_FIXED_LIFETIME_VALUE || 15000
    });
    emits('update:visible', false);
    form.resetForm();
  } catch (err) {
    console.error(err);
  } finally {
    loading.value = false;
  }
});

/**
 * On change Modal Visibility
 * @param val
 */
function onChange(val: boolean) {
  submitted.value = false;
  emits('update:visible', val);
}
</script>

<style scoped>
:deep(.p-message .p-message-wrapper .p-message-close) {
  flex-shrink: 0;
}
</style>
